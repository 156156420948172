import { catchError, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HandleError, HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { BusinessProfileService } from '@services/business-profile/business-profile.service';
import { BusinessProfile } from '@shared/meta-data/business-profile.meta';
import { AppConfig } from '@app/app.config';
import { IResponse, LinkedAccount, LinkedAccountEvent } from '@shared/meta-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LinkedAccountsService {
  private readonly handleError: HandleError;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly httpErrorHandler: HttpErrorHandler,
    private readonly businessProfileService: BusinessProfileService
  ) {
    this.handleError = httpErrorHandler.createHandleError('Linked Accounts');
  }

  installShopify(subdomain: string): void {
    this.businessProfileService.getBusinessProfile()
      .pipe(take(1))
      .subscribe({
        next: (businessProfile: BusinessProfile) => {
          window.open(
            `${AppConfig.API_URL}/shopify/install?shop=${subdomain}.myshopify.com&user_id=${businessProfile.user_id}&business_profile_id=${businessProfile.business_profile_id}`, '_self'
          );
        }
      });
  }

  installStripe(): void {
    this.businessProfileService.getBusinessProfile()
      .pipe(take(1))
      .subscribe({
        next: (businessProfile: BusinessProfile) => {
          window.open(
            `${AppConfig.API_URL}/stripe/apps/install?user_id=${businessProfile.user_id}&business_profile_id=${businessProfile.business_profile_id}`, '_self'
          );
        }
      });
  }

  installAmazon(): void {
    this.businessProfileService.getBusinessProfile()
      .pipe(take(1))
      .subscribe({
        next: (businessProfile: BusinessProfile) => {
          window.open(
            `${AppConfig.API_URL}/amazon/install?user_id=${businessProfile.user_id}&business_profile_id=${businessProfile.business_profile_id}`, '_self'
          );
        }
      });
  }

  fetchLinkedAccounts() {
    return this.httpClient.get<IResponse<LinkedAccount>>(`${AppConfig.API_URL}/linked-tokens`)
      .pipe(
        map(({ content }) => content),
        catchError((err) => {
          return this.handleError('Fetch Linked Account', null)(err);
        })
      );
  }

  fetchLinkedAccountsEvents() {
    return this.httpClient.get<IResponse<LinkedAccountEvent>>(`${AppConfig.API_URL}/linked-tokens/events`)
      .pipe(
        map(({ content }) => content),
        catchError((err) => {
          return this.handleError('Fetch Linked Account Events', null)(err);
        })
      );
  }

  unlinkAccount(tokenId: number): Observable<IResponse<{}>> {
    return this.httpClient.delete<IResponse<{}>>(`${AppConfig.API_URL}/linked-tokens/${tokenId}`)
      .pipe(catchError((err) => this.handleError('Remove Linked Account', null)(err)));
  }
}